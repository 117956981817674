/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100&display=swap');

body {
    background-color: #FFF !important;
    /* font-family: 'Poppins', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

    font-family: 'Josefin Sans', sans-serif !important;

    overflow-x: hidden;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;

    background-image: url(./images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    z-index: -1;
}

.pageWrapper .container-fluid {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.upperNav {
    height: 60px;
    padding: 5px;
}

.upperNav__links {
    margin-right: 15px;
}

.upperNav__links .link:not(:last-child),
.upperNav__info>div:not(:last-child) {
    margin-right: 10px;
}

.upperNav__info>div {
    padding: 4px;
    cursor: pointer;
}

.upperNav__info>div:hover {
    background-color: rgba(255, 255, 255, .2);
    border-radius: 50%;
}

.upperNav__links .link {
    color: #FFF;
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
}

.upperNav__info .menu-icon svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: #FFF;
}

.upperNav__info .userProfile {
    border-radius: 50%;
    overflow: hidden;
}

.upperNav__info .userProfile img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.canvasStyel {
    width: 100% !important;
    max-width: 100% !important;
    height: 100vh !important;

}


.screen-bg {
    position: absolute;
    top: 0;
    left: 0;
}

.mainContainer {
    width: 100%;
    min-height: calc(100vh - 60px);
    height: 100%;
    margin-top: -50px;
}

.brand-logo {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.search-input {
    max-width: 800px;
    min-height: 200px;
    width: 100%;
    margin: 1rem auto 0;
    padding: 3rem 2rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .3);
}

.search-input .form-floating {
    width: 100%;
    margin: 0 auto;
    transition: .5s;
}

.search-input input {
    border-radius: 15px;
    background: #EDEDED;
    color: #202124 !important;
    border-width: 1px !important;
    padding-right: 60px !important;
}

.search-input input,
.search-input label {
    padding-left: calc(1em + .75rem) !important;
}

.search-input input:focus {
    background-color: #EDEDED;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-color: #5f6368;
}

.search-input .inputIcons {
    background-color: #6d147a;
    width: 51px;
    height: 49px;
    border-radius: 24px;
    position: absolute;
    top: 3px;
    right: 5px;
    border: none;
}

.search-input .inputIcons svg {
    color: white;
    width: 42px;
    height: 42px;
}

.error-msg {
    width: 100%;
    height: auto;

    margin-top: .5rem;
    padding: .25rem 1rem;
    color: red;
    background-color: rgb(255, 189, 189);
    text-align: center;
    text-transform: capitalize;
    border-radius: 8px;
    font-size: 14px;
}

/* custom popups */
.modal {
    font-size: 12px;
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}



/* loader */

.generalLoader {
    position: fixed;
    /* max-width: 100px; */
    z-index: 1;
    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

.loader img {
    max-width: 100px;
    max-height: 100px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 35px;
    height: 34px;
    margin: 5px;
    border: 5px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.react-tel-input .form-control {
    padding-left: 4rem !important;
}

.react-tel-input .form-control:focus {
    border-color: #000 !important;
    box-shadow: none !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}